import React from "react";

import Layout from "../../../components/Layout";
import PolicySection from "../../../content/PolicySection";

const Policy = (props) => {
  return (
    <Layout>
      <PolicySection isPage />
    </Layout>
  );
};

export default Policy;
